/* eslint-disable no-shadow */
import axios from '../../services/axios';

const state = () => ({
  interviews: [],
  numberOfPages: 1,
  selectedYear: null,
  selectedTopic: null,
  sortKey: 'name',
  sortDirection: 'ASC',
});

const getters = {};

const mutations = {
  SET_INTERVIEWS(state, payload) {
    state.interviews = payload;
  },
  SET_NUMBER_OF_PAGES(state, payload) {
    state.numberOfPages = payload;
  },
  SET_SELECTED_YEAR(state, year) {
    state.selectedYear = year;
  },
  SET_SELECTED_TOPIC(state, topic) {
    state.selectedTopic = topic;
  },
  SET_SORT_KEY(state, key) {
    state.sortKey = key;
  },
  SET_SORT_DIRECTION(state, direction) {
    state.sortDirection = direction;
  },
};

const actions = {
  async LOAD_INTERVIEWS(
    { commit, rootState },
    { currentPage, year, topic, sortKey, sortDirection } = {}
  ) {
    const params = {
      page: currentPage || 1,
      organizationalUnit: rootState.organization.selectedOU.id,
    };

    const usingSortKey = sortKey || state.sortKey || 'name';
    const usingSortDirection = sortDirection || state.sortDirection || 'ASC';

    params[`order[${usingSortKey}]`] = usingSortDirection;

    if(usingSortKey !== 'name') {
      params['order[name]'] = 'ASC';
    }

    if (year) {
      params['updatedAt[after]'] = `${year}-01-01`;
      params['updatedAt[strictly_before]'] = `${year + 1}-01-01`;
    }
    if (topic) {
      params['template.topic'] = topic.id;
    }
    const { data } = await axios.get('/interviews', {
      params,
    });
    commit('SET_INTERVIEWS', data['hydra:member']);
    commit('SET_NUMBER_OF_PAGES', Math.ceil(data['hydra:totalItems'] / 15));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
